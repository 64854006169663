<template>
  <div class="container py-5">
    <p class="fw-bold">第61屆上海國際美博會</p>
    
    <p>時間：2023 06/11-13</p>
    <p>地點：上海市諸光路國家會展中心</p>
    <p>展位：5.2A10L</p>
    <p>
     在夏日的碧浪中，荷蘭OBSERV在五月與中國博覽會告別後，迎來了中國國內規模最大、歷史最悠久的美業展覽-第61屆上海國際美博會，荷蘭OBSERV將再次重磅亮相CIBE，秉自然之心，成美業之勢，5.2A10L荷蘭OBSERV皮膚檢測儀誠邀您同赴盛會，共締美麗~
    </p>

    <img
      class="img-sm rounded"
      src="@/assets/images/zh-TW/news/new_25_1.jpg"
    />

  </div>
</template>
<script>
export default {};
</script>
